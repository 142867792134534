//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import tableList from "../../business/tobacco-environment/table-list";
import soilHeader from "../../business/tobacco-environment-echarts/soil-header";
import environmentHeader from "../../business/tobacco-environment-echarts/environment-header";

export default {
	name: "head-check-quality",
	components: {
		tableList,
		soilHeader,
		environmentHeader
	},
	computed: {
		// 区分选择的图
		headCheck() {
			return this.$store.state['tobaccoEnvironment'].headCheck;
		}
	},
	async mounted() {
		await this.getDict();
	},

	data() {
		return {
			// 项目类型
			quaDictType: 'tobacco_planting_environment',
			// 土壤类型
			soilDictType: 'soil_type',
			btn: 1,
			url: this.$store.dispatch('tobaccoEnvironment/changeHeadCheck', '1'),
		}
	},

	methods: {
		changeCheck() {
			this.btn = 1;
			this.$store.dispatch('tobaccoEnvironment/changeHeadCheck', '1');
			this.$store.dispatch("tobaccoEnvironment/changeTagsList", []);
			this.$store.dispatch("tobaccoEnvironment/changeParamsSingle", {key: 'index', value: []});
			this.$store.dispatch("tobaccoEnvironmentEcharts/changeParamsSingle", {key: 'index', value: []});
		},
		changeCheck1() {
			this.btn = 2;
			this.$store.dispatch('tobaccoEnvironment/changeHeadCheck', '2');
			this.$store.dispatch("tobaccoEnvironment/changeTagsList", []);
			this.$store.dispatch("tobaccoEnvironment/changeParamsSingle", {key: 'index', value: []});
			this.$store.dispatch("tobaccoEnvironmentEcharts/changeParamsSingle", {key: 'index', value: []});
		},
		changeCheck2() {
			this.btn = 3;
			this.$store.dispatch('tobaccoEnvironment/changeHeadCheck', '3');
			this.$store.dispatch("tobaccoEnvironment/changeTagsList", []);
			this.$store.dispatch("tobaccoEnvironment/changeParamsSingle", {key: 'index', value: []});
			this.$store.dispatch("tobaccoEnvironmentEcharts/changeParamsSingle", {key: 'index', value: []});
		},
		changeCheck3() {
			this.btn = 4;
			this.$store.dispatch('tobaccoEnvironment/changeHeadCheck', '4');
			this.$store.dispatch("tobaccoEnvironment/changeTagsList", []);
			this.$store.dispatch("tobaccoEnvironment/changeParamsSingle", {key: 'index', value: []});
			this.$store.dispatch("tobaccoEnvironmentEcharts/changeParamsSingle", {key: 'index', value: []});
		},

		/**
		 * 获取下拉单数据
		 */
		async getDict() {
			this.dataType = [];
			// 数据类型
			let res = await this.$store.dispatch('dict/getDictEntity',
				{'type': [this.quaDictType, this.soilDictType]});
			await this.$store.dispatch("tobaccoEnvironmentEcharts/changeDictList", res.data);
			let quaDictList = [];
			let soilDictList = [];
			if (res.data) {
				res.data.forEach(item => {
					if (item.levelFlag === 1 && item.type === this.quaDictType) {
						quaDictList.push(item);
					}
					if (item.type === this.soilDictType) {
						soilDictList.push(item);
					}
				})
			}
			await this.$store.dispatch("tobaccoEnvironmentEcharts/changeQuaDictList", quaDictList);
			await this.$store.dispatch("tobaccoEnvironmentEcharts/changeSoilDictList", soilDictList);
		}
	}
}
