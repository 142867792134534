//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {dataUtil} from "../../../utils/data-util";
import environment from "../tobacco-environment/environment";
import exportHeader from "../../../components/tobacco/export-header";
import indicatorCustomizationEnvironment from "../../business/tobacco-management/indicator-customization-environment";

export default {
	name: "table-list",
	components: {
		indicatorCustomizationEnvironment,
		environment,
		exportHeader
	},
	inject: ["hideLoading", "showLoading"],
	computed: {
		areaTree() {
			return this.$store.state['areaManagement'].areaTree;
		},
		pointerList() {
			return this.$store.state['tobaccoEnvironment'].pointerList;
		},
		dialogVisible() {
			return this.$store.state['tobaccoEnvironment'].dialogVisible;
		},
		tags() {
			return this.$store.state['tobaccoEnvironment'].tagsList;
		},
		types() {
			return this.$store.state['tobaccoEnvironment'].type;
		},
		exportFlag() {
			return this.$store.state['tobaccoEnvironment'].exportFlag;
		},
		queryParams() {
			return this.$store.state['tobaccoEnvironment'].queryParams;
		}
	},
	data() {
		return {
			showYear: true,
			showSoilType: true,
			dict: [],
			pointer: [],
			pointerType: '全部',
			soilTypeList: [],
			soilTypes: '',
			dataType: [],
			type: '1',
			enDictType: 'tobacco_planting_environment',
			soilDictType: 'soil_type',
			areaTreeProps: {
				'value': 'code',
				'label': 'name',
				'children': 'childrenList',
				'checkStrictly': true
			},
			dataTimes: [],
			year: '',
			indexList: [],
		}
	},
	mounted() {
		this.getSelects().then(() => {
			this.changeData(this.type);
			this.search();
		});
	},
	methods: {
		/**
		 * 查询
		 */
		async search() {
			this.showLoading();
			// 传参
			await dataUtil.changeParamsSingle("pageNum", 1);
			await dataUtil.search();
			await this.$store.dispatch("tobaccoEnvironment/changeExportFlag", true);
			this.hideLoading();
		},

		/**
		 * 更改数据类型
		 * @param val
		 */
		changeData(val) {
			switch (val) {
				case '1':
					this.showYear = false;
					this.showSoilType = false;//地理环境没有土壤类型
					break;
				case '2':
					this.showYear = true;
					this.showSoilType = false;//气象气温没有土壤类型
					break
				case '3':
					this.showYear = true;
					this.showSoilType = false;//气象气温没有土壤类型
					break
				case '4':
					this.showYear = true;
					this.showSoilType = false;//气象气温没有土壤类型
					break
				case '5':
					this.showYear = true;
					this.showSoilType = true;
					break
				case '6':
					this.showYear = true;
					this.showSoilType = true;
					break
				case '7':
					this.showYear = true;
					this.showSoilType = true;
					break;
				default:
					break;
			}
			this.pointer = [];
			let temp = [];
			temp.push("1");
			temp.push(val);
			dataUtil.getType(temp);
			// 获取选取值的id
			let id = null;
			this.dataType.forEach(item => {
				if (item.value === val) {
					id = item.id;
				}
			})
			// 更改指标类型
			if (id) {
				this.dict.forEach(item => {
					if (item.parentId === id) {
						this.pointer.push(item);
					}
				})
			}
			this.$store.dispatch("tobaccoEnvironment/changePointerList", this.pointer);
		},

		/**
		 * 更改年份
		 */
		changeYear() {
			dataUtil.changeParamsSingle("year", this.year);
		},

		/**
		 * 更改产地
		 * @param val
		 */
		changeArea(val) {
			dataUtil.changeParamsSingle("areaCode", dataUtil.getAreaName(val, this.areaTree));
		},

		/**
		 * 更改土壤类型
		 */
		changeSoil(val) {
			let soilType = '';
			this.soilTypeList.forEach(item => {
				if (item.value === val) {
					soilType = item.name;
				}
			})
			dataUtil.changeParamsSingle("soilType", soilType);
		},


		/**
		 * 关闭标签
		 * @param tag
		 */
		handleClose(tag) {
			let res = [...this.queryParams.index];
			let i = res.length;
			while (i--) {
				if (res[i].id === tag.id) {
					res.splice(i, 1);
				}
			}
			this.tags.splice(this.tags.indexOf(tag), 1);
			dataUtil.changeParamsSingle("index", res);
			// 查询数据
			this.search();
		},

		/**
		 * 显示对话框
		 */
		showDialog() {
			// 初始化列表
			let res = [...this.queryParams.index];
			// 还原指标列表
			this.indexList = [...dataUtil.reductionIndexList(res)];
			// 显示弹窗
			this.$store.dispatch("tobaccoEnvironment/changeDialogVisible", true);
		},

		/**
		 * 获取下拉单数据
		 */
		async getSelects() {
			this.dataType = [];
			// 数据类型
			let res = await this.$store.dispatch('dict/getDictEntity',
				{'type': [this.enDictType, this.soilDictType]});
			this.dict = res.data;
			if (this.dict) {
				// 获取检测类型与土壤类型
				this.dict.forEach(item => {
					if (item.levelFlag === 1 && item.type === this.enDictType) {
						this.dataType.push(item);
					}
					if (item.type === this.soilDictType) {
						this.soilTypeList.push(item);
					}
				})
			}
			// 年份
			this.dataTimes = dataUtil.getDateTimes();
			// 行政树
			await this.$store.dispatch('areaManagement/getAreaTree');
		},
	}
}
