//
//
//
//
//
//
//
//
//
//

import headCheckEnvironment from "../tobacco-management/head-check-environment";
import PageHeader from "../../../components/page-header";

export default {
	name: "index",
	components: {
		PageHeader,
		headCheckEnvironment,
	},
	computed: {
		headCheck() {
			return this.$store.state['tobaccoEnvironment'].headCheck;
		}
	}
}

