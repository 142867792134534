//
//
//
//
//
//
//
//
//
//
//
//
//
//


import BigNumber from "bignumber.js";

export default {
	name: "environment-area-echarts",
	computed: {
		list() {
			return this.$store.state['tobaccoEnvironmentEcharts'].list;
		},
		colorList() {
			return this.$store.state['tobaccoEnvironmentEcharts'].colorList;
		},
		year() {
			return this.$store.state['tobaccoEnvironmentEcharts'].queryParams.year;
		},
		pointerName() {
			return this.$store.state['tobaccoEnvironmentEcharts'].pointerName;
		},
		queryParams() {
			return this.$store.state['tobaccoEnvironmentEcharts'].queryParams;
		}
	},
	data() {
		return {
			areaList: [],
			barData: [],
			barOption: {
				title: {
					text: '',
					left: 'center'
				},
				legend: {
					top: '10%',
					type: 'scroll',
					orient: 'horizontal'
				},
				toolbox: {
					show: true,
					feature: {//可下载为图
						saveAsImage: {show: true}
					}
				},
				grid: {
					top: '30%',
					right: '10%',
				},
				label: {
					show: true,
					color: '#002',
					position: 'top',
				},
				tooltip: {},
				xAxis: {
					type: 'category',
					name: '产地',
					data: [],
					axisLabel: {interval: 0, rotate: 20}
				},
				yAxis: {
					type: "value",
					name: "土壤类型", //y坐标轴的名字
					axisLine: {show: true},
					axisTick: {show: true},
				},
				dataZoom: [
					{
						xAxisIndex: 0, //这里是从X轴的0刻度开始
						show: true, //是否显示滑动条，不影响使用
						type: "slider", // 这个 dataZoom 组件是 slider 型 dataZoom 组件
						start: 0, // 从头开始。
						end: 30, // 一次性展示6个。
						height: 20,  //X轴滚动条宽度
					},
					{
						type: 'inside',
						start: 94,
						end: 100,
					},
				],
				series: [{
					type: 'bar',
					data: [],
					itemStyle: {
						normal: {
							//颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
							color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 0.8, [{
								offset: 0,
								color: '#09e9f3'
							}, {
								offset: 1,
								color: '#99f4ff'
							}])
						}
					}
				}]
			},
			pieOption: {
				title: {
					text: '',
					left: 'center'
				},
				legend: {
					top: '10%',
					type: 'scroll',
					orient: 'horizontal'
				},
				toolbox: {
					show: true,
					feature: {//可下载为图
						saveAsImage: {show: true}
					}
				},
				label: {
					formatter: '{b}: {c}  ({d}%)'
				},
				series: [
					{
						name: '',
						type: 'pie',
						radius: [50, 150],
						center: ['50%', '60%'],
						roseType: 'area',
						itemStyle: {
							borderRadius: 8
						},
						data: [],
					}
				]
			}
		}
	},
	watch: {
		list(value) {
			if (value) {
				this.getChart();
			}
		},
	},
	methods: {
		/**
		 * 得到图形
		 */
		getChart() {
			// 处理数据
			if (this.list.length === 0) {
				this.barOption.title.text = "暂无数据";
				this.barOption.xAxis.data = [];
				this.barOption.series[0].data = [];
				this.barOption.title.text = "暂无数据";
				this.pieOption.series[0].data = [];
				return;
			}
			// 获取品种和行政区列表
			this.getArr();
			// 获取value值
			this.getValue();
			if (this.queryParams.type !== '1') {
				this.barOption.title.text = this.year[0] + "年-" + this.pointerName + "-统计图";
			} else {
				this.barOption.title.text = this.pointerName + "-统计图";
			}
			this.barOption.yAxis.name = this.pointerName;
			this.barOption.xAxis.data = this.areaList;
			this.barOption.series[0].data = this.barData;
			// 基于准备好的dom，初始化echarts实例
			this.getPie(true, '');
		},

		/**
		 * 获取饼状图
		 * @param val
		 */
		getPie(val, name) {
			this.pieOption.series[0].data = this.getPieSeries(this.areaList, this.barData);
			if (this.queryParams.type !== '1') {
                if(this.queryParams.type === '2' || this.queryParams.type === '3' || this.queryParams.type === '4'){
                    this.pieOption.title.text = this.year[0] + "年-" + this.pointerName + "-占比图";
                }else {
                    this.pieOption.title.text = this.year[0] + "年-" + name + "-" + this.pointerName + "-占比图";
                }
			} else {
				this.pieOption.title.text = this.pointerName + "-占比图";
			}
		},

		/**
		 * 获取品种与行政区列表
		 * @param lists
		 * @returns {*[]}
		 */
		getArr() {
			this.areaList = [];
			// 获取行政区,选择了行政区列表就以行政区列表为准
			if (this.queryParams.areaCode && this.queryParams.areaCode.length > 0) {
				this.queryParams.areaCode.forEach(items => {
					if (items && items.length > 0) {
						this.areaList.push(items[items.length - 1]);
					}
				})
			} else {
				// 以结果列表的为准
				this.list.forEach(item => {
					// 默认使用两级
					if (item['province'] === '云南省') {
						if (!this.areaList.includes(item['stateCity'])) {
							this.areaList.push(item['stateCity']);
						}
					}
				})
			}
		},

		/**
		 * 获取
		 * @param value
		 */
		getValue() {
			this.barData = [];
			this.areaList.forEach(a => {
				let count = new BigNumber("0");
				let sum = new BigNumber("0");
				this.list.forEach(c => {
					if (a && c) {
						let flagA = false;
						let flagB = false;
						let flagC = false;
						let flagD = false;
						if (c['province']) {
							flagA = c['province'].indexOf(a) !== -1 || a.indexOf(c['province']) !== -1;
						}
						if (c['stateCity']) {
							flagB = c['stateCity'].indexOf(a) !== -1 || a.indexOf(c['stateCity']) !== -1;
						}
						if (c['distinctCounty']) {
							flagC = c['distinctCounty'].indexOf(a) !== -1 || a.indexOf(c['distinctCounty']) !== -1;
						}
						if (c['townShip']) {
							flagD = c['townShip'].indexOf(a) !== -1 || a.indexOf(c['townShip']) !== -1;
						}
						if ((flagA || flagB || flagC || flagD)) {
							let value = new BigNumber(c['value']);
							if (value > 0) {
								sum = sum.plus(value);
								count = count.plus(new BigNumber("1"));
							}
						}
					}
				})
				this.barData.push(count > 0 ? sum.div(count).dp(2).toNumber() : 0);
			})
		},

		/**
		 * 拼接饼图的series
		 * @param names
		 * @param values
		 * @returns {*[]}
		 */
		getPieSeries(names, values) {
			let series = [];
			let size = this.colorList.length;
			if (names) {
				for (let i = 0; i < names.length; i++) {
					series.push({
						value: values[i],
						name: names[i],
						itemStyle: {
							normal: {
								//颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
								color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 0.8, [{
									offset: 0,
									color: this.colorList[i % size][0]
								}, {
									offset: 1,
									color: this.colorList[i % size][1]
								}])
							}
						},
					})
				}
			}
			return series;
		}
	}
}
