//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {echartsUtil} from "../../../utils/echarts-util";
import soilTypeEchartsHeader from "../../business/tobacco-environment-echarts/soil-type-echarts-header";

export default {
	name: "environment-header",
	components: {
		soilTypeEchartsHeader,
	},
	data() {
		return {
			// 查询项目
			type: '',
			quaDictListNew: [],
			// 指标
			pointerType: '',
			pointerList: [],
			quaDictType: 'tobacco_planting_environment',
		}
	},
	created() {
		// 处理一个项目列表，去掉不需要的几个
		this.handleQuaList();
		this.type = this.quaDictListNew[0].value;
		this.changeData(this.type);
	},
	computed: {
		dictList() {
			return this.$store.state['tobaccoEnvironmentEcharts'].dictList;
		},
		quaDictList() {
			return this.$store.state['tobaccoEnvironmentEcharts'].quaDictList;
		},
		// 区分选择的图
		headCheck() {
			return this.$store.state['tobaccoEnvironment'].headCheck;
		},
	},
	methods: {

		/**
		 * 处理项目列表，去掉不需要显示的
		 */
		handleQuaList() {
			this.quaDictListNew = [];
			this.quaDictList.forEach(item => {
				if (item.value !== '1' && item.value !== '2' && item.value !== '3' && item.value !== '4') {
					this.quaDictListNew.push(item);
				}
			})
		},

		/**
		 * 更改数据类型
		 * @param val
		 */
		changeData(val) {
			this.pointerList = [];
			this.getType(val);
			// 获取选取值的id
			let id = null;
			this.quaDictList.forEach(item => {
				if (item.value === val) {
					id = item.id;
				}
			})
			// 更改指标类型
			if (id) {
				this.dictList.forEach(item => {
					if (item.parentId === id) {
						this.pointerList.push(item);
					}
				})
				if (this.pointerList.length > 0) {
					this.pointerType = this.pointerList[0].value;
					this.changePointer();
				}
			}
			this.$store.dispatch("tobaccoEnvironment/changePointerList", this.pointerList);
		},

		/**
		 * 获取类型
		 * @param val
		 */
		getType(val) {
			if (val) {
				let temp = [];
				temp.push("1");
				temp.push(val);
				echartsUtil.getType(temp);
			}
		},

		/**
		 * 更改指标
		 */
		changePointer() {
			echartsUtil.changeParamsSingle("pointerType", this.pointerType);
			this.pointerList.forEach(item => {
				if (item.value === this.pointerType) {
					this.$store.dispatch("tobaccoEnvironmentEcharts/changePointerName", item.name);
				}
			})
		},
	}
}
