//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {dataUtil} from '@/utils/data-util';
import indicatorCustomizationEnvironment from "../../business/tobacco-management/indicator-customization-environment";
import soilAreaEcharts from "../../business/tobacco-environment-echarts/soil-area-echarts";
import {echartsUtil} from "@/utils/echarts-util";
import environmentAreaEcharts from "../../business/tobacco-environment-echarts/environment-area-echarts";

export default {
	name: "soil-area-echarts-header",
	components: {
		indicatorCustomizationEnvironment,
		soilAreaEcharts,
		environmentAreaEcharts
	},
	inject: ["hideLoading", "showLoading"],
	computed: {
		// 行政树
		areaTree() {
			return this.$store.state['areaManagement'].areaTree;
		},
		// 控制指标编辑界面
		dialogVisible() {
			return this.$store.state['tobaccoEnvironment'].dialogVisible;
		},
		// 标签
		tags() {
			return this.$store.state['tobaccoEnvironment'].tagsList;
		},
		soilDictList() {
			return this.$store.state['tobaccoEnvironmentEcharts'].soilDictList;
		},
		type() {
			return this.$store.state['tobaccoEnvironmentEcharts'].queryParams.type;
		},
		queryParams() {
			return this.$store.state['tobaccoEnvironmentEcharts'].queryParams;
		}
	},
	data() {
		return {
			// 年份
			dataTimes: [],
			yearSingle: 2010,
			// 行政区
			areaTreeProps: {
				'value': 'code',
				'label': 'name',
				'children': 'childrenList',
				'checkStrictly': true
			},
			// 行政区
			areaTreePropsMulti: {
				'value': 'code',
				'label': 'name',
				'children': 'childrenList',
				'checkStrictly': true,
				'multiple': true
			},
			// 产地
			areaMulti: [],
			// 土壤类型
			soilTypes: [],
			indexList: [],
		}
	},
	async mounted() {
		// 年份
		this.dataTimes = dataUtil.getDateTimes();
		// 初始化数据
		this.changeYearSingle();
		await echartsUtil.changeParamsSingle("soilType", []);
		await echartsUtil.changeParamsSingle("areaCode", []);
		await this.search();
		this.hideLoading();
	},
	methods: {
		/**
		 * 查询
		 */
		async search() {
			this.showLoading();
			await echartsUtil.search();
			this.hideLoading();
		},

		/**
		 * 更改年份
		 */
		changeYearSingle() {
			echartsUtil.changeParamsSingle("year", [this.yearSingle]);
		},

		/**
		 * 更改产地
		 * @param val
		 */
		changeAreaMulti(val) {
			let arr = echartsUtil.getAreaName(val, this.areaTree);
			echartsUtil.changeParamsSingle("areaCode", arr);
		},

		/**
		 * 更改土壤类型
		 */
		changeSoil(val) {
			let temp = [];
			this.soilDictList.forEach(items => {
				val.forEach(item => {
					if (items.value === item) {
						temp.push(items.name);
					}
				})
			})
			echartsUtil.changeParamsSingle("soilType", temp);
		},

		/**
		 * 关闭标签
		 * @param tag
		 */
		handleClose(tag) {
			let res = [...this.queryParams.index];
			let i = res.length;
			while (i--) {
				if (res[i].id === tag.id) {
					res.splice(i, 1);
				}
			}
			this.tags.splice(this.tags.indexOf(tag), 1);
			echartsUtil.changeParamsSingle("index", res);
			echartsUtil.search();
		},

		/**
		 * 显示对话框
		 */
		showDialog() {
			// 初始化列表
			let res = [...this.queryParams.index];
			// 还原指标列表
			this.indexList = [...dataUtil.reductionIndexList(res)];
			// 显示弹窗
			this.$store.dispatch("tobaccoEnvironment/changeDialogVisible", true);
		},
	}
}
