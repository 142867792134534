//
//
//
//
//
//
//
//
//
//
//
//
//
//


import BigNumber from "bignumber.js";

export default {
	name: "soil-year-echarts",
	computed: {
		list() {
			return this.$store.state['tobaccoEnvironmentEcharts'].list;
		},
		colorList() {
			return this.$store.state['tobaccoEnvironmentEcharts'].colorList;
		},
		pointerName() {
			return this.$store.state['tobaccoEnvironmentEcharts'].pointerName;
		},
		queryParams() {
			return this.$store.state['tobaccoEnvironmentEcharts'].queryParams;
		}
	},
	data() {
		return {
			YearArr: [],
			areaList: [],
			barSource: [
				// ['品种', 'KRK26', '云烟87', '云烟97', '红大', '云烟85', 'NC297', 'NC102', 'K326'],
				// ['玉溪市', 0, 0, 0, 0, 0, 3, 0, 32],
				// ['昆明市', 0, 142, 5, 290, 24, 72, 68, 143],
				// ['临沧市', 36, 198, 6, 0, 0, 0, 0, 106],
				// ['曲靖市', 0, 139, 131, 116, 0, 0, 0, 49],
				// ['楚雄彝族自治州', 2, 67, 0, 23, 0, 3, 0, 201],
				// ['红河哈尼族彝族自治州', 0, 129, 63, 115, 0, 14, 0, 230],
				// ['保山市', 0, 33, 0, 0, 35, 0, 0, 63],
				// ['文山壮族苗族自治州', 0, 64, 10, 0, 0, 0, 0, 9],
				// ['大理白族自治州', 0, 0, 0, 24, 0, 0, 0, 0],
				// ['普洱市', 0, 51, 31, 0, 6, 0, 0, 12]
			],
			barOption: {
				title: {
					text: '',
					left: 'center'
				},
				legend: {
					top: '10%',
					type: 'scroll',//控制图例显示在一行
					orient: 'horizontal'//控制图例显示在一行
				},
				toolbox: {
					show: true,
					feature: {//可下载为图
						saveAsImage: {show: true}
					}
				},
				grid: {
					top: '30%',
					right: '10%',
				},
				label: {
					show: true,
					color: '#002',
					position: 'top',
					fontSize: '8px',
				},
				tooltip: {},
				dataset: {
					source: []
				},
				xAxis: {
					type: 'category',
					name: '年份',
					axisLabel: {interval: 0, rotate: 20}
				},
				yAxis: {
					type: "value",
					name: "土壤类型", //y坐标轴的名字
					axisLine: {show: true},
					axisTick: {show: true}

				},
				dataZoom: [
					{
						xAxisIndex: 0, //这里是从X轴的0刻度开始
						show: true, //是否显示滑动条，不影响使用
						type: "slider", // 这个 dataZoom 组件是 slider 型 dataZoom 组件
						start: 0, // 从头开始。
						end: 30, // 一次性展示6个。
						height: 20,  //X轴滚动条宽度
					},
					{
						type: 'inside',
						start: 94,
						end: 100,
					},
				],
				series: []
			},
			pieOption: {
				title: {
					text: '',
					left: 'center'
				},
				legend: {
					top: '10%',
					type: 'scroll',//控制图例显示在一行
					orient: 'horizontal'//控制图例显示在一行
				},
				toolbox: {
					show: true,
					feature: {//可下载为图
						saveAsImage: {show: true}
					}
				},
				label: {
					formatter: '{d}%'
				},
				series: [
					{
						name: '',
						type: 'pie',
						radius: [50, 150],
						center: ['50%', '60%'],
						roseType: 'area',
						itemStyle: {
							borderRadius: 8
						},
						data: [],
					}
				]
			},
			pieNames: [],
		}
	},
	watch: {
		list(value) {
			if (value) {
				this.getChart();
			}
		},
	},
	methods: {
		/**
		 * 得到图形
		 */
		getChart() {
			// 处理数据
			if (this.list.length === 0) {
				this.pieOption.title.text = "暂无数据";
				this.barSource.series = [];
				this.barOption.dataset.source = [];
				this.barOption.title.text = "暂无数据";
				this.pieOption.series[0].data = [];
				return;
			}
			// 获取年份和行政区列表
			this.getArr();
			// 获取value值
			this.getValue();
			this.barOption.title.text = this.queryParams.soilType + "-" + this.pointerName + "-统计图";
			this.barOption.yAxis.name = this.pointerName;
			this.barOption.series = this.getBarSeries(this.areaList);
			this.barOption.dataset.source = this.barSource;
			// 基于准备好的dom，初始化echarts实例
			this.getPie(true, '');
			this.$refs.bar.chart.on('click', 'series', (params) => {
				this.getPie(false, params.name);
			})
		},

		/**
		 * 获取饼状图
		 * @param val
		 */
		getPie(val, year) {
			if (val) {
				year = this.barSource[1][0];
			}
			if (this.queryParams.type === '5' &&
				(this.pointerName === 'pH值' ||
					this.pointerName === '有机质' ||
					this.pointerName === '全氮' ||
					this.pointerName === '全磷' ||
					this.pointerName === '全钾' ||
					this.pointerName === '速效氮' ||
					this.pointerName === '速效磷' ||
					this.pointerName === '速效钾')) {
				// 特殊指标的图
				this.specialPie(year);
			} else {
				// 平常指标的图
				this.usualPie(val, year);
			}
			this.pieOption.title.text = year + '年-' + this.queryParams.soilType + "-" + this.pointerName + "-占比图";
		},

		/**
		 * 指标	区间
		 * pH值	5.5~6.5
		 * 有机质 g/kg	10-20
		 * 全氮 g/kg	0.7-1.8
		 * 全磷 g/kg	0.6-1.8
		 * 全钾 g/kg	>8.0
		 * 速效氮（mg/kg）	100~150
		 * 速效磷（mg/kg）	10~35
		 * 速效钾（mg/kg）	120~220
		 *
		 * 获取特殊的图
		 * @param val
		 * @param year
		 */
		specialPie(year) {
			let values = [];
			// 根据不同的条件计算出不同的结果
			switch (this.pointerName) {
				case "pH值":
					values = this.getValues(year, 5.5, 6.5);
					break;
				case "有机质":
					values = this.getValues(year, 10, 20);
					break;
				case "全氮":
					values = this.getValues(year, 0.7, 1.8);
					break;
				case "全磷":
					values = this.getValues(year, 0.6, 1.8);
					break;
				case "全钾":
					values = this.getValues(year, 8, 100000);
					break;
				case "速效氮":
					values = this.getValues(year, 100, 150);
					break;
				case "速效磷":
					values = this.getValues(year, 10, 35);
					break;
				case "速效钾":
					values = this.getValues(year, 120, 220);
					break;
				default:
					break;
			}
			this.pieOption.series[0].data = this.getPieSeries(this.pieNames, values);
		},
		/**
		 * 获取优质与不优质的数据
		 * @param year
		 * @param start
		 * @param end
		 */
		getValues(year, start, end) {
			let arr = [start + '~' + end, '其他']
			this.pieNames = arr;
			let highQuality = new BigNumber("0");
			let lowQuality = new BigNumber("0");
			start = new BigNumber(start.toString());
			end = new BigNumber(end.toString());
			let values = [];
			this.list.forEach(c => {
				if (c) {
					if (c['particularYear'].toString() === year.toString()) {
						if (start.lte(new BigNumber(c['value'])) && end.gte(new BigNumber(c['value']))) {
							highQuality = highQuality.plus(new BigNumber("1"));
						} else {
							lowQuality = lowQuality.plus(new BigNumber("1"));
						}
					}
				}
			})
			values.push(highQuality.toNumber());
			values.push(lowQuality.toNumber());
			return values;
		},


		/**
		 * 获得平常的图
		 * @param val
		 * @param year
		 */
		usualPie(val, year) {
			// 是否是首次
			if (val) {
				let arr = [...this.barSource[1]];
				arr.shift();
				this.pieOption.series[0].data = this.getPieSeries(this.areaList, arr);
			} else {
				let values = [];
				this.barSource.forEach(item => {
					if (item[0].toString() === year.toString()) {
						values = [...item];
					}
				})
				values.shift();
				this.pieOption.series[0].data = this.getPieSeries(this.areaList, values);
			}
		},


		/**
		 * 获取品种与行政区列表
		 * @param lists
		 * @returns {*[]}
		 */
		getArr() {
			this.yearArr = [];
			this.areaList = [];
			// 年份
			if (this.queryParams.year && this.queryParams.year.length > 0) {
				this.yearArr = this.queryParams.year;
			} else {
				// 以结果列表的为准
				this.list.forEach(item => {
					if (item.particularYear && !this.yearArr.includes(item.particularYear)) {
						this.yearArr.push(item.particularYear);
					}
				})
			}
			this.yearArr.sort();
			// 获取行政区,选择了行政区列表就以行政区列表为准
			if (this.queryParams.areaCode && this.queryParams.areaCode.length > 0) {
				this.queryParams.areaCode.forEach(items => {
					if (items && items.length > 0) {
						this.areaList.push(items[items.length - 1]);
					}
				})
			} else {
				// 以结果列表的为准
				this.list.forEach(item => {
					// 默认使用两级
					if (item['province'] === '云南省') {
						if (!this.areaList.includes(item['stateCity'])) {
							this.areaList.push(item['stateCity']);
						}
					}
				})
			}
		},

		/**
		 * 获取
		 * @param value
		 */
		getValue() {
			this.barSource = [];
			let source = [];
			let breed = ['年份'];
			this.areaList.forEach(item => {
				breed.push(item);
			})
			source.push(breed);
			this.yearArr.forEach(a => {
				let temp = [a];
				this.areaList.forEach(b => {
					let count = new BigNumber("0");
					let sum = new BigNumber("0");
					this.list.forEach(c => {
						if (a && b && c) {
							let flagA = false;
							let flagB = false;
							let flagC = false;
							let flagD = false;
							if (c['province']) {
								flagA = c['province'].indexOf(b) !== -1 || b.indexOf(c['province']) !== -1;
							}
							if (c['stateCity']) {
								flagB = c['stateCity'].indexOf(b) !== -1 || b.indexOf(c['stateCity']) !== -1;
							}
							if (c['distinctCounty']) {
								flagC = c['distinctCounty'].indexOf(b) !== -1 || b.indexOf(c['distinctCounty']) !== -1;
							}
							if (c['townShip']) {
								flagD = c['townShip'].indexOf(b) !== -1 || b.indexOf(c['townShip']) !== -1;
							}
							if ((flagA || flagB || flagC || flagD) && c['particularYear'].toString() === a.toString()) {
								let value = new BigNumber(c['value']);
								if (value > 0) {
									sum = sum.plus(value);
									count = count.plus(new BigNumber("1"));
								}
							}
						}
					})
					temp.push(count > 0 ? sum.div(count).dp(2).toNumber() : 0);
				})
				source.push(temp);
			})
			this.barSource = source;
		},


		/**
		 * 拼接柱状图的series
		 * @param names
		 * @returns {*[]}
		 */
		getBarSeries(names) {
			let series = [];
			let size = this.colorList.length;
			if (names) {
				for (let i = 0; i < names.length; i++) {
					series.push({
						type: 'bar',
						name: names[i],
						itemStyle: {
							normal: {
								//颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
								color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 0.8, [{
									offset: 0,
									color: this.colorList[i % size][0]
								}, {
									offset: 1,
									color: this.colorList[i % size][1]
								}])
							}
						}
					})
				}
			}
			return series;
		}
		,

		/**
		 * 拼接饼图的series
		 * @param names
		 * @param values
		 * @returns {*[]}
		 */
		getPieSeries(names, values) {
			let series = [];
			let size = this.colorList.length;
			if (names) {
				for (let i = 0; i < names.length; i++) {
					series.push({
						value: values[i],
						name: names[i],
						itemStyle: {
							normal: {
								//颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
								color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 0.8, [{
									offset: 0,
									color: this.colorList[i % size][0]
								}, {
									offset: 1,
									color: this.colorList[i % size][1]
								}])
							}
						},
					})
				}
			}
			return series;
		}
	}
}
