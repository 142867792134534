import { render, staticRenderFns } from "./soil-year-echarts-header.vue?vue&type=template&id=b253e2dc&scoped=true&"
import script from "./soil-year-echarts-header.vue?vue&type=script&lang=js&"
export * from "./soil-year-echarts-header.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b253e2dc",
  null
  
)

export default component.exports