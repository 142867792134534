//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {dataUtil} from "../../../utils/data-util";
import {echartsUtil} from "../../../utils/echarts-util";

export default {
	name: "indicator-customization-environment",
	inject: ["hideLoading", "showLoading"],
	props: ['indexList'],
	computed: {
		pointerList() {
			return this.$store.state['tobaccoEnvironment'].pointerList;
		},
		// 区分选择的图
		headCheck() {
			return this.$store.state['tobaccoEnvironment'].headCheck;
		},
	},
	data() {
		return {
			item: {
				pointer: '',
				radio: '1',
				selEq: '',
				num1: null,
				num2: null,
				num3: null
			},
			eqs: [
				">=",
				"<=",
				"=",
				"<",
				">"
			],
		}
	},
	methods: {
		/**
		 * 删除
		 * @param item
		 * @param index
		 */
		delItem(item, index) {
			this.indexList.splice(index, 1);
		},

		/**
		 * 新增
		 */
		addList() {
			// 校验数据
			if (!this.checkData()) {
				return;
			}
			// 新增
			let temp = {
				pointer: '',
				radio: '1',
				selEq: '',
				num1: null,
				num2: null,
				num3: null
			};
			this.indexList.push(temp);
		},

		/**
		 * 关闭对话框
		 */
		closeDialog() {
			this.$store.dispatch("tobaccoEnvironment/changeDialogVisible", false);
		},

		/**
		 * 提交
		 */
		async submit() {
			// 校验数据
			if (!this.checkData()) {
				return;
			}
			// 提交数据
			this.showLoading();
			let tags = [];
			let query = [];
			let id = 1;
			this.indexList.forEach(item => {
				let str = item.pointer + " ";
				if (item.radio === '1') {
					str += item.selEq + "" + item.num1;
					query.push({
						'id': id,
						'type': '1',
						'name': item.pointer,
						'sign': item.selEq,
						'value': Number(item.num1)
					})
				}
				if (item.radio === '2') {
					str += item.num2 + "~" + item.num3;
					query.push({
						'id': id,
						'type': '2',
						'name': item.pointer,
						'value1': Number(item.num2),
						'value2': Number(item.num3)
					})
				}
				tags.push({name: str, type: '', id: id});
				id++;
			});
			await this.$store.dispatch("tobaccoEnvironment/changeTagsList", tags);
			this.closeDialog();
			if (this.headCheck === '1') {
				await dataUtil.changeParamsSingle("index", query);
				await dataUtil.changeParamsSingle("pageNum", 1);
				await dataUtil.search();
			} else {
				await echartsUtil.changeParamsSingle("index", query);
				await echartsUtil.search();
			}
			this.hideLoading();
		},

		/**
		 * 校验数据是否完整正确
		 */
		checkData() {
			if (this.indexList && this.indexList.length > 0) {
				if (!this.indexList.at(-1).pointer) {
					this.$message.warning("请先选择指标名称");
					return false;
				}
				if (this.indexList.at(-1).radio === '1') {
					if (!this.indexList.at(-1).selEq ||
						!this.indexList.at(-1).num1) {
						this.$message.warning("请填好不等式");
						return false;
					}
					if (!dataUtil.isNumber(this.indexList.at(-1).num1)) {
						this.$message.warning("只能填写数值");
						return false;
					}
				}
				if (this.indexList.at(-1).radio === '2') {
					if (!this.indexList.at(-1).num2
						|| !this.indexList.at(-1).num3) {
						this.$message.warning("请填好范围");
						return false;
					}
					if (!dataUtil.isNumber(this.indexList.at(-1).num2) ||
						!dataUtil.isNumber(this.indexList.at(-1).num3)) {
						this.$message.warning("只能填写数值");
						return false;
					}
				}
			}
			return true;
		}
	}
}
