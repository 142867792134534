import { render, staticRenderFns } from "./soil-year-echarts.vue?vue&type=template&id=489df57b&scoped=true&"
import script from "./soil-year-echarts.vue?vue&type=script&lang=js&"
export * from "./soil-year-echarts.vue?vue&type=script&lang=js&"
import style0 from "./soil-year-echarts.vue?vue&type=style&index=0&id=489df57b&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "489df57b",
  null
  
)

export default component.exports